/* eslint-disable */

// eslint disabled for this file, since this file, potentially can be reusable both for isml and client js

var breakpoints = require('./mediaBreakpoints.json');

/**
 * Returns slider carousel config (banners slider)
 * @return {Config} carousel config
 */
function slider() {
    return {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        rows: 0,
        fade: true,
        dots: true,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand'
    };
}

/**
 * Returns dotless slider carousel config (banners slider)
 * @return {Config} carousel config
 */
function sliderdotless() {
    return {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        rows: 0,
        fade: true,
        dots: false,
        arrows: false,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand'
    };
}

/**
 * Returns content carousel config (image with text carousel)
 * @return {Config} carousel config
 */
function contentcarousel() {
    return {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
}

/**
 * Returns content carousel config (image with text carousel)
 * @return {Config} carousel config
 */
 function minicartcarousel() {
    return {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>'
    };
}

/**
 * Returns Instagram carousel config
 * @return {Config} carousel config
 */
 function instagramcarousel() {
    return {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
}

/**
 * Returns recommendations carousel config (product tiles carousel)
 * @return {Config} carousel config
 */
function recommendations() {
    return {
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        infinite: true,
        useTransform: false,
        speed: 300,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
}

/**
 * Returns tri carousel config
 * @return {Config} carousel config
 */
function tricarousel() {
    return {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        rows: 0,
        dots: false,
        arrows: false,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 1,
                    autoplay: false,
                    autoplaySpeed: 5000
                }
            }
        ]
    };
}
/**
 * Returns Instagram carousel config
 * @return {Config} carousel config
 */
function categorycarousel() {
    return {
        slidesToShow: 6,
        slidesToScroll: 1,
        rows: 0,
        dots: false,
        arrows: true,
        autoplay: false,
        infinite: false,
        lazyLoad: 'progressive',        
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        responsive: [
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 4,               
                    centerMode: false,
                    arrows: false,
                }
            },
        ]
    };
}

/**
 * Returns product feature carousel config
 * @return {Config} carousel config
 */
function productcarousel() {
    return {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 1.5,
                    autoplay: false,
                    infinite: false,
                    arrows: false,
                    centerMode: false,
                    autoplaySpeed: 5000
                }
            }
        ]
    };
}

/**
 * Returns product feature carousel config
 * @return {Config} carousel config
 */
function productcarousel3() {
    return {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 2.5,
                    autoplay: false,
                    infinite: false,
                    arrows: false,
                    centerMode: false,
                    autoplaySpeed: 5000
                }
            },
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 2.5,
                    autoplay: false,
                    infinite: false,
                    arrows: false,
                    centerMode: false,
                    autoplaySpeed: 5000
                }
            }
        ]
    };
}

/**
 * Returns product feature carousel config
 * @return {Config} carousel config
 */
function productcarousel4() {
    return {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        centerMode: false,
        rows: 0,
        dots: false,
        arrows: true,
        prevArrow: '<span class="slick-arrow-left fi fi--carrot-left"></span>',
        nextArrow: '<span class="slick-arrow-right fi fi--carrot-right"></span>',
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: breakpoints.ls,
                settings: {
                    slidesToShow: 2.5,
                    autoplay: false,
                    infinite: false,
                    arrows: false,
                    centerMode: false,
                    autoplaySpeed: 5000
                }
            },
            {
                breakpoint: breakpoints.md,
                settings: {
                    slidesToShow: 2.5,
                    autoplay: false,
                    infinite: false,
                    arrows: false,
                    centerMode: false,
                    autoplaySpeed: 5000
                }
            }
        ]
    };
}
module.exports = {
    slider: slider,
    sliderdotless: sliderdotless,
    contentcarousel: contentcarousel,
    instagramcarousel: instagramcarousel,
    minicartcarousel: minicartcarousel,
    recommendations: recommendations,
    tricarousel: tricarousel,
    categorycarousel: categorycarousel,
    productcarousel: productcarousel,
    productcarousel3: productcarousel3,
    productcarousel4: productcarousel4
};

'use strict';

/**
 * Renders picturetag.isml template
 * @param {Object} pdict - data object to generate HTML
 * @returns {string} HTML markup as string
 */
const template = (pdict) => `
    <picture>
        <source
            ${pdict.lazyload || pdict.lazyload_carousel ? 'data-srcset=' : 'srcset='}"${pdict.image.srcset.mobile}"
            media="(max-width: 743px)"
        />

        <source
            ${pdict.lazyload || pdict.lazyload_carousel ? 'data-srcset=' : 'srcset='}"${pdict.image.srcset.tablet}"
            media="(min-width: 744px) and (max-width: 1023px)"
        />

        <img
            src="${pdict.lazyload || pdict.lazyload_carousel ? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' : pdict.image.srcset.desktop }"
            ${pdict.lazyload ? 'data-src=' + pdict.image.srcset.desktop : ''}
            ${pdict.lazyload_carousel ? 'data-lazy=' + pdict.image.srcset.desktop : ''}
            class="${pdict.classname || ''} ${pdict.lazyload ? ' lazy' : ''}"
            alt="${pdict.altname || pdict.image.alt || ''}"
            title="${pdict.titlename || pdict.image.title || ''}"
            itemprop="${pdict.itemprop || ''}"
        />
    </picture>`;

module.exports = template;

let currentSlide = 0;
const progressBar = document.querySelector('.progress-bar');
let startX;
let endX;
let timer;
const intervalTime = 5000; // Time in milliseconds (5 seconds)

function startTimer() {
    timer = setInterval(() => {
        nextSlide();
    }, intervalTime);
}

function resetTimer() {
    clearInterval(timer);
    startTimer();
}

function showSlide(index) {
    const slides = document.querySelectorAll('.carousel-a-plus-item');
    const totalSlides = slides.length;

    if (index >= totalSlides) {
        currentSlide = 0;
    } else if (index < 0) {
        currentSlide = totalSlides - 1;
    } else {
        currentSlide = index;
    }

    const newTransformValue = -currentSlide * 100;
    document.querySelector('.carousel-a-plus-inner').style.transform = `translateX(${newTransformValue}%)`;
    updateProgressBar();
    resetTimer(); // Reset timer on slide change

}

function nextSlide() {
    showSlide(currentSlide + 1);
}

function prevSlide() {
    showSlide(currentSlide - 1);
}

function updateProgressBar() {
    const slides = document.querySelectorAll('.carousel-a-plus-item');
    const totalSlides = slides.length;
    const progress = ((currentSlide + 1) / totalSlides) * 100;
    progressBar.style.width = `${progress}%`;
}

if(document.querySelector('.carousel-a-plus')){
    document.querySelector('.carousel-a-plus-button.next').addEventListener('click', nextSlide);
    document.querySelector('.carousel-a-plus-button.prev').addEventListener('click', prevSlide);

    // Touch event handlers
    document.querySelector('.carousel-a-plus').addEventListener('touchstart', function(event) {
        startX = event.touches[0].clientX;
    }, false);

    document.querySelector('.carousel-a-plus').addEventListener('touchend', function(event) {
        endX = event.changedTouches[0].clientX;
        handleSwipe();
    }, false);

    function handleSwipe() {
        if (startX > endX + 50) {
            nextSlide();
        } else if (startX < endX - 50) {
            prevSlide();
        }
    }
    showSlide(0);
    startTimer(); // Start the timer
}

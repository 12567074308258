'use strict';

window.PinchZoom = require('core/thirdParty/pinchZoom/pinch-zoom.js');
var utils = require('../utils/utils');

const config = {
    pinchZoom: {
        draggableUnzoomed: false,
        minZoom: 1,
        maxZoom: 2.5,
        setOffsetsOnce: true
    }
};

const selectors = {
    carousel: '.js-carousel',
    modal: '.js-modal, .modal'
};

/**
 * Handles very specific small functionalities
 */

module.exports = {
    // Global slick init, the configuration parameters are passed in the data-slick attribute in isml
    slickInit: () => { $(selectors.carousel).slick(); },
    recommendationsStart: () => {
        $(document).on('recommendations:start', () => {
            $(`${selectors.carousel}:not(.slick-initialized)`).slick();
        });
    },
    onModalHide: () => {
        $(selectors.modal).on('hidden.bs.modal', (e) => {
            const thatModal = e.delegateTarget;

            // Unslick slider if it has one
            const innerCarousel = $(thatModal).find(selectors.carousel);

            if (innerCarousel.length) {
                innerCarousel.slick('unslick');
            }
        });
    },

    onModalShow: () => {
        $(selectors.modal).on('shown.bs.modal', (e) => {
            const thatModal = e.delegateTarget;

            // Init slider if it has one
            const innerCarousel = $(thatModal).find(selectors.carousel);

            if (innerCarousel.length && !innerCarousel.hasClass('slick-initialized')) {
                innerCarousel.slick();
            }
        });
    },

    productTile: () => {
        $('.main').on('mouseenter', '.js-tile-image', function () {
            let $image = $(this);
            if ($image.data('alt-src') && !$image.hasClass('error')) {
                $image.data('primary-src', $image.attr('src'));
                $image.attr('src', $image.data('alt-src'));
            }
        }).on('mouseleave', '.js-tile-image', function () {
            let $image = $(this);
            if ($image.data('primary-src') && ($image.attr('src') !== $image.data('primary-src'))) {
                $image.attr('src', $image.data('primary-src'));
            }
        });
    },

    globalEventHandlers: () => {
        // events that should be run only once

        // Trigger error event on all ajax calls.
        $(document).ajaxError(function (event, jqxhr) {
            if (window.pushGAEvent && jqxhr.responseJSON && jqxhr.responseJSON.dataLayerViewData) {
                window.pushGAEvent(
                    jqxhr.responseJSON.dataLayerViewData.event,
                    jqxhr.responseJSON.dataLayerViewData.dataLayerData
                );
            }
        });

        if (utils.isTouchDevice()) {
            utils.smartResize(() => {
                // fix resize bug
                $('.js-pinch-zoom').each((_, item) => {
                    let $parent = $(item).parent();
                    if ($parent.hasClass('pinch-zoom-container')) {
                        $parent.height($(item).height());
                    }
                });
            });
        }
    },

    initPinchZoom: () => {
        if (utils.isTouchDevice()) {
            const pinchZoomElements = document.querySelectorAll('.js-pinch-zoom');

            pinchZoomElements.forEach((item) => {
                if (!item.parentElement.classList.contains('pinch-zoom-container')) {
                    // eslint-disable-next-line
                    new window.PinchZoom.default(item, config.pinchZoom);
                }
            });
        }
    }
};

'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');

var processInclude = require('base/util');

$(function () {
    processInclude(require('./utils/device'));
    processInclude(require('./utils/lazyLoad'));
    processInclude(require('./utils/smoothScroll'));
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/globalPromotion'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/listSlider'));
    processInclude(require('./components/backToTop'));
    processInclude(require('./components/carouselAPlus'));
    processInclude(require('./components/common'));
    processInclude(require('wishlist/product/wishlistHeart'));
});

// Popper for Bootstrap Dropdown
require('popper.js');

// Bootstrap components
require('bootstrap/js/src/util.js');
require('bootstrap/js/src/collapse.js');
require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/tab.js');
require('bootstrap/js/src/carousel.js');
require('bootstrap/js/src/alert.js');

// Slick carousel
require('slick-carousel/slick/slick.min.js');

require('base/components/spinner');

'use strict';

var cart = require('../cart/cart');
var lazyLoad = require('../utils/lazyLoad');

var updateMiniCart = true;

const $miniCartBtn = $('.js-minicart');
const $miniCartModal = $('#miniCartModal');

/**
 * openMiniCart
 * @param {string} url - action-url of js-minicart
 * @param {Object} event - "event" of the button you clicked on
*/
function openMiniCart(url, event = false) {
    if (url) {
        if ($(event.target).parents('.product-quickview').length) {
            // if we open miniCartModal from quickViewModal, we need to wait until quickViewModal is closed.
            // Without this we will have problems with bootstrap class and styles on 'body'.
            $('#quickViewModal').one('hidden.bs.modal', function () {
                $miniCartModal.modal('show');
            });
        } else {
            $miniCartModal.modal('show');
        }

        $('.js-minicart-modal-content').spinner().start();

        $.get(url, function (data) {
            $('.js-minicart-modal-content').empty();
            $('.js-minicart-modal-content').append(data);
            updateMiniCart = false;
            const $carousel = $('#miniCartModal .js-carousel');

            if ($carousel.length) {
                // init slick in miniCartModal
                $carousel.slick();
            }

            // Cubersource invegration start
            var isPaypalEnabled = !!($('#paypal_enabled').length > 0 && document.getElementById('paypal_enabled').value === 'true');
            var isGooglePayEnabled = !!($('#isGooglePayEnabled').length > 0 && $('#isGooglePayEnabled').val() === 'true');

            if (isPaypalEnabled) {
                paypalhelper.paypalMini(); // eslint-disable-line
            }
            if (isGooglePayEnabled && $('#enableGooglePayOnMiniCart').val() === 'true') {
                var googlePay = require('cybersource/googlepay');
                googlePay.onGooglePayLoaded();
            }

            lazyLoad.update();

            // Cubersource invegration end

            $.spinner().stop();

            $('body').trigger('wishlist:updateHearts');
            $('body').trigger('onMiniCartShow:addCouponFormEvents');

            if ($carousel.length) {
                // Update slick in miniCartModal after opening from quickView.
                // If we remove this line, the slick width will not be calculated correctly.
                // This is due to the double "fade" effect when opening one modal window from another.
                $miniCartModal.one('shown.bs.modal', function () {
                    $carousel.slick('refresh');
                });
            }
        });
    }
}

module.exports = {
    miniCart: function () {
        cart();

        $miniCartBtn.on('count:update', function (event, count) {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.js-minicart .minicart-quantity').text(count.quantityTotal);
                $('.js-minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
                $('.js-minicart-icon-wrapper').toggleClass('is-filled', count.quantityTotal > 0);
            }
        });

        $miniCartBtn.on('click', function (event) {
            if (!updateMiniCart) {
                $miniCartModal.modal('show');
                $('body').trigger('wishlist:updateHearts');
                return;
            }

            const url = $miniCartBtn.data('action-url');

            openMiniCart(url);

            event.stopImmediatePropagation();
        });
        $miniCartBtn.on('click focusout', function (event) {
            if ((event.type === 'focusout' && $miniCartBtn.has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.js-minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                event.stopPropagation();
                return;
            }

            if (!($(document).find('.paypal-checkout-sandbox').length > 0)) {
                $miniCartModal.modal('hide');
            }

            event.stopImmediatePropagation();
        });
        $('body').on('change', '.js-minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                window.location.reload();
            }
        });
        $('body').on('product:afterAddToCart', function () {
            updateMiniCart = true;
        });
        $('body').on('cart:update', function () {
            updateMiniCart = true;
        });
    },
    openMiniCart: function () {
        $('body').on('minicart:open', function (e, response) {
            openMiniCart(response.url, response.event);
        });
    }
};

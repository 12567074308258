'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var utils = require('../utils/utils');
const bodyScrollLock = require('body-scroll-lock');

var cache = {
    $body: $('body'),
    $mainMenu: $('.js-main-menu'),
    $mainMenuSiblings: $('.js-main-menu').siblings(),
    $siteHeader: $('.js-site-header'),
    $siteHeaderSiblings: $('.js-site-header').siblings(),
    $navbarToggler: $('.js-navbar-toggler')
};

var closeMenuFlyouts = function () {
    let $dropdowns = cache.$mainMenu.find('.nav-item.show').removeClass('show');
    $dropdowns.children('.nav-link').attr('aria-expanded', 'false');
    $dropdowns.children('.nav-item-dropdown').removeClass('show').attr('aria-hidden', 'true');
};

var toggleHamburgerMenu = function () {
    let wasClosed = cache.$mainMenu.attr('aria-hidden');
    cache.$mainMenu.toggleClass('is-active');
    cache.$siteHeader.toggleClass('is-flyout-open');
    cache.$mainMenu.attr('aria-hidden', wasClosed === 'true' ? 'false' : 'true');
    cache.$mainMenuSiblings.attr('aria-hidden', wasClosed === 'true' ? 'false' : 'true');
    cache.$siteHeaderSiblings.attr('aria-hidden', wasClosed === 'false' ? 'true' : 'false');
    if (wasClosed === 'true') {
        $('.main-menu .nav.navbar-nav .nav-link').first().trigger('focus');
        bodyScrollLock.disableBodyScroll($('.js-hamburger-menu-scrollable')[0]);
    } else {
        bodyScrollLock.clearAllBodyScrollLocks();
    }
};

/**
 * appends params to a url
 * @param {string} $navlink - menu .nav-link
 * @param {boolean} isDesktop - mobile or desktop
 * @param {boolean} isOpenAction - wether we need specifically OPEN or CLOSE
 * @return {void}
 */
function toggleMenuDropdown($navlink, isDesktop, isOpenAction) {
    let ariaExpanded = $navlink.attr('aria-expanded');

    if ((isOpenAction === true && ariaExpanded === 'true')
        || (isOpenAction === false && ariaExpanded === 'false')) return;

    let $siblings = $navlink.parent().toggleClass('show').siblings();
    let $dropdowns = $siblings.removeClass('show')
        .children('.nav-item-dropdown')
        .attr('aria-hidden', 'true');
    if (!isDesktop) $dropdowns.slideUp();

    $siblings.children('.nav-link').attr('aria-expanded', 'false');

    $dropdowns = $navlink.siblings('.nav-item-dropdown').attr('aria-hidden', ariaExpanded !== 'true' ? 'false' : 'true');
    if (isDesktop) { $dropdowns.toggleClass('show'); } else $dropdowns.slideToggle();
    $navlink.attr('aria-expanded', ariaExpanded === 'true' ? 'false' : 'true');
}

module.exports = function () {
    var isDesktop = function () {
        return cache.$mainMenu.css('position') !== 'fixed';
    };

    var skipClick = false;

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .menu-content__link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    toggleMenuDropdown(menuItem.children('.nav-link').first(), isDesktop(menuItem[0]), true);
                    menuItem.find('ul > li > a').first().trigger('focus');
                } else {
                    menuItem.removeClass('show').children('.nav-item-dropdown').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.nav-item-dropdown').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    toggleMenuDropdown(menuItem.children('.nav-link').first(), isDesktop(menuItem[0]), false);
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.nav-item-dropdown').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                }
            },
            27: function (menuItem) { // escape
                if (!isDesktop(menuItem[0])) {
                    cache.$navbarToggler.trigger('click').trigger('focus');
                } else closeMenuFlyouts();
            }
        },
        function () {
            return $(this).parent();
        });

    $('.js-menulink-has-subcats').on('click', function(){
        var menuLinks = $(this).next();
        if(!menuLinks.hasClass('show')){
            $('.js-menu-content').stop().slideUp().removeClass('show');
            menuLinks.stop().slideDown().addClass('show');
        }
    });

    $('.js-main-menu .nav-item:not(.disabled) [data-toggle="custom-dropdown"]')
        .on('click', function (e) {
            // Mobile (hamburger) menu accordion emulation
            if (!isDesktop(this)) {
                e.preventDefault();
                toggleMenuDropdown($(this), false);
            } else if (skipClick) {
                // prevent first click after menu opened on large iPad Pro
                e.preventDefault();
                skipClick = false;
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                if (utils.isTouchDevice()) skipClick = true; // prevent first click after menu opened on large iPad Pro
                toggleMenuDropdown($(this), true);
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) closeMenuFlyouts();
        });

    $('.js-menu-close-button, .js-navbar-toggler, .js-header-backdrop').on('click', function (e) {
        e.preventDefault();
        toggleHamburgerMenu(e);
    });

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 44) {
            $('.site-header.full-width').addClass('sticky');
        } else {
            $('.site-header.full-width').removeClass('sticky');
        }
    });

    var initMainMenu = function () {
        if (!isDesktop(this)) {
            cache.$mainMenu.attr('aria-hidden', 'true');
        }
    };

    // init some layout-specific menu attributes and states on load
    initMainMenu();
};
